<template>
  <div class="series">
    <div class="series-bg"></div>
    <div class="series-body">
      <series-title
        level="2"
        class="series-title"
      >
        <img
          :src="titleImageSrc"
          :alt="titleText"
        >
      </series-title>
      <swiper
        :options="swiperOptions"
        ref="titlesSwiper"
        class="series-list"
      >
        <swiper-slide
          v-for="(item, i) in titleList"
          :key="i"
          :ref="`index-${i}`"
          :data-series-id="item.id"
          class="list-item"
        >
          <img :src="packageImageSrc(item.id)" alt="">
        </swiper-slide>
        <div
          class="prev-button"
          slot="button-prev"
        />
        <div
          class="next-button"
          slot="button-next"
        />
      </swiper>
      <div class="action-buttons">
        <back-button @click="goToTop" />
        <select-button
          buttonColor="gold"
          class="select-button"
          @click="goToHeroList"
        >
          <img
            :src="require(`@images/series/${locale}/select-btn_txt.png`)"
            alt="Select"
          >
        </select-button>
      </div>
      <div class="series-desc">
        <p class="inner" v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Api from '@libraries/api/'
import RequestTitles from '@requests/titles'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import SeriesTitle from '@components/global/title.vue'
import BackButton from '@components/global/back-button.vue'
import SelectButton from '@components/global/button.vue'

export default {
  name: 'Series',

  components: {
    swiper,
    swiperSlide,
    SeriesTitle,
    BackButton,
    SelectButton,
  },

  props: {
    locale: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      swiperOptions: {
        loop: true,
        navigation: { 
          nextEl: '.next-button',
          prevEl: '.prev-button',
        },
        preventInteractionOnTransition: true,
        init: false,
        allowTouchMove: true,
        slidesPerView: 1,
        shortSwipes: false,
        longSwipesRatio: 0.1,
        longSwipesMs: 50,
        on: {
          init: () => {
            this.currentTitleId = this.currentTitleId || this.titleList[0].title
            const target = Object.values(this.swiper.slides).find(slide => {
              return +slide.dataset.seriesId === this.currentTitleId
            })

            this.swiper.navigation.onNextClick = this.handleNextClick
            this.swiper.navigation.onPrevClick = this.handlePrevClick

            this.swiper.slideTo(
              +target.dataset.swiperSlideIndex + 1,
              0
            )
          },
          slideChange: () => {
            const titleItem = this.titleList.find((title, i) => i === this.swiper.realIndex)

            if (titleItem === undefined) {
              return
            }
            this.currentTitleId = titleItem.id
            this.$router.replace(
              {
                query: {
                  series: this.currentTitleId
                }
              },
              () => {}
            )
          }
        }
      },
      swiperNavigation: {},
      currentTitleId: null,
      defaultTitleId: 101,
      titleList: [],
    }
  },

  created () {
    // @FIXME defaultIdを外せるようなら外す
    this.currentTitleId = +this.$route.query.series || this.defaultTitleId
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),

    titleImageSrc () {
      if (!this.currentTitleId) {
        return
      }
      return require(`@images/series/${this.locale}/txt_series_${this.currentTitleId}.png`)
    },

    swiper () {
      return this.$refs.titlesSwiper.swiper
    },

    currentTitle () {
      if (!this.currentTitleId) {
        return
      }
      return this.titleList.length > 0 ? this.titleList.find(title => title.id === Number(this.currentTitleId)) : null
    },

    titleText () {
      if (!this.currentTitle) {
        return
      }
      return this.currentTitle.title
    },

    description () {
      if (!this.currentTitle) {
        return
      }
      return this.currentTitle.description
    }
  },

  async mounted () {
    await Promise.all([
      await this.getTitles(),
      await sleep(this.loaderConfig.minDisplayDuration)
    ])
    this.$nextTick(async () => {
      this.$refs.titlesSwiper.swiper.init()
      await this.hideLoader()
    })
  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),

    async getTitles () {
      await Api.call(new RequestTitles({ locale: this.locale }))
        .then((titles) => {
          this.titleList = titles
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    packageImageSrc (titleId) {
      return require(`@images/series/${this.locale}/img_series_${titleId}.png`)
    },

    goToHeroList () {
      this.$router.push({
        name: 'HeroList',
        params: {
          seriesId : this.currentTitleId
        }
      })
    },

    goToTop () {
      this.$router.push({ name: 'Top' })
    },

    animateButton (el) {
      gsap
        .from(el, {
          scale: 0.4,
          ease: `back.out(1)`,
          duration: 0.25,
          onStart: () => {
            gsap.set(el, { scale: 1 })
          },
          onComplete: () => {
            gsap.set(el, { scale: 1 })
          }
        })
    },

    handleNextClick (ev) {
      const swiper = this.swiper
      
      ev.preventDefault()
      ev.stopPropagation()

      if (swiper.isEnd && !swiper.params.loop || swiper.animating) {
        return
      }
      
      this.animateButton(ev.currentTarget)
      swiper.slideNext()
    },

    handlePrevClick (ev) {
      const swiper = this.swiper
      
      ev.preventDefault()
      ev.stopPropagation()
      
      if (swiper.isBeginning && !swiper.params.loop || swiper.animating) {
        return
      }
      
      this.animateButton(ev.currentTarget)
      swiper.slidePrev()
    }
  }
}
</script>

<style lang="scss" scoped>
.series {
  padding-top: 111px + 40px;
}
  .series-bg {
    $this: #{&};
    position: absolute;
    top: 0;
    width: 100%;
    height: 1000px;
    background-position: left 111px;
    background-repeat: no-repeat;
    background-image: url(#{$img-path}series/bg.jpg);
    $per: (544/1000 * 100%);
    mask-image: linear-gradient(
      180deg,
      rgba(#000, 1) 0%,
      rgba(#000, 1) $per,
      rgba(#000, 0) 91%
    );
    mask-repeat: repeat-x;
    mask-size: 100% 100%;
    mask-position: left top;

    // NOTE IEにはmaskが効かない
    // Edgeは開発者ツールが落ちる
    @at-root {
      .-ie,
      .-edge {
        #{$this} {
          background-image: url(#{$img-path}series/bg_masked.png);
        }
      }
    }
  }
  .series-body {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 55px;

    > .series-list {
      margin-top: 22px;
    }
    > .action-buttons {
      margin-top: 36px;
    }
    > .series-desc {
      margin-top: 38px;
      margin-left: auto;
      margin-right: auto;
    }
  }
    .series-list {
      $h: 508px;
      $parts-h: 4px;
      background:
        url(#{$img-path}series/list_bg_p1.png) no-repeat,
        url(#{$img-path}series/list_bg_p1.png) no-repeat,
        url(#{$img-path}series/list_bg_p2.png) no-repeat;
      background-position: 0 0, 0 100%, 0 $parts-h;
      background-size: 100% $parts-h, 100% $parts-h, 100% ($h - $parts-h*2);
      height: $h;
      z-index: 15;

      .list-item {
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          pointer-events: none;
        }
      }
      .next-button,
      .prev-button {
        $this: #{&};
        position: absolute;
        top: 160px;
        width: 128px;
        height: 204px;
        z-index: 100;
        backface-visibility: hidden;
        transform-origin: 50% 46%;

        &:focus {
          outline: none;
        }

        @at-root {
          .-pc {
            #{$this} {
              @include tap-event();
            }
          }
        }
      }
      .next-button {
        background: url(#{$img-path}series/nav_next.png) no-repeat;
        right: 21px;
      }
      .prev-button {
        background: url(#{$img-path}series/nav_prev.png) no-repeat;
        left: 21px;
      }
    }
    .action-buttons {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      width: 700px;

      > .back-button {
        margin-top: 2px;
      }
      > .select-button {
        width: 544px;
      }
    }
    .series-desc {
      border-style: solid;
      border-width: 52px 80px;
      border-image: url(#{$img-path}box/bluebox_bg.png) 52 80 fill stretch;
      width: 722px;

      & > .inner {
        font-size: $fs-middle;
        color: $color-sub;
        text-shadow: $ts-base;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-left: -16px;
        margin-right: -16px;
      }
    }
    .sample-button {
      > .inner {
        
      }
    }
</style>